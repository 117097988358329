
import { Vue, Component, Ref } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import { FolderDataSource } from "@/data/Tree/FolderDataSource";
import { mdiAccount, mdiDomain, mdiPlus } from "@mdi/js";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import { required } from "@/cms-services/consts";
import moment from "moment";
@Component({
  components: { DataEditorDialog },
})
export default class Home extends Vue {
  @Ref("editor") editor!: DataEditorDialog;
  moment = moment;
  icons: any = {
    plus: mdiPlus,
  };
  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
    },
    className: "PrivateClient",
  });

  phoneDataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
    },
    className: "PrivateClient",
  });

  folderDataSource: FolderDataSource = new FolderDataSource({
    className: "ClientFolder",
    rootFolder: "mainFolder",
  });
  $message: any;

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Клиенты",
        disabled: true,
      },
    ];
  }

  headers: any = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Дата создания",
      value: "createDate",
      sortable: true,
    },
    {
      text: "Заголовок",
      value: "fullName",
      sortable: true,
    },
    {
      text: "Email",
      value: "email",
      sortable: true,
    },

    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
    {
      text: "Тип клиента",
      value: "className",
      sortable: false,
    },
  ];

  getClientTypeColor(className) {
    switch (className) {
      case "PrivateClient":
        return "success";

      case "CorporateClient":
        return "primary";
    }
  }
  getClientTypeIcon(className) {
    switch (className) {
      case "PrivateClient":
        return mdiAccount;

      case "CorporateClient":
        return mdiDomain;
    }
  }

  model: any = {
    firstName: "",
    lastName: "",
    patronymic: "",
    caption: "",
    phone: "",
    email: "",
    clientType: "",
    folderId: null,
  };

  fields: any = [
    {
      name: "clientType",
      editor: "enum",
      attrs: {
        type: "text",
        outlined: true,
        label: "Тип клиента",
      },
      config: {
        type: "select",
        getItems: "getTypes",
        valueKeyName: "className",
        labelKeyName: "caption",
        attrs: {
          small: true,
        },
      },
    },
    {
      editor: "string",
      attrs: {
        type: "email",
        outlined: true,
        label: "Почта",
      },
      name: "email",
      validations: [
        {
          validator: "required",
          value: true,
          errorMessage: "Поле не может быть пустым",
        },
        {
          validator: "regular",
          value: "email",
          errorMessage: "Введите корректный адрес",
        },
      ],
    },
    {
      editor: "string",
      attrs: {
        type: "text",
        outlined: true,
        label: "Телефон",
        mask: "+7(###) ###-##-##",
      },
      name: "phone",
      validations: [
        {
          validator: "required",
          errorMessage: "Поле не может быть пустым",
          value: true,
        },
        {
          validator: "min",
          errorMessage: "Поле не может содержать меньше 15 символов",
          value: 15,
        },
      ],
    },
    {
      editor: "string",
      bindedFieldName: "clientType",
      bindedFieldValue: "PrivateClient",
      attrs: {
        type: "text",
        outlined: true,
        label: "Имя",
      },
      name: "firstName",
      validations: [
        {
          validator: "required",
          errorMessage: "Поле не может быть пустым",
        },
      ],
    },
    {
      editor: "string",
      bindedFieldName: "clientType",
      bindedFieldValue: "PrivateClient",
      attrs: {
        type: "text",
        outlined: true,
        label: "Фамилия",
      },
      name: "lastName",
      validations: [
        {
          validator: "required",
          errorMessage: "Поле не может быть пустым",
        },
      ],
    },
    {
      editor: "string",
      bindedFieldName: "clientType",
      bindedFieldValue: "PrivateClient",
      attrs: {
        type: "text",
        outlined: true,
        label: "Отчество",
      },
      name: "patronymic",
    },
    {
      editor: "string",
      bindedFieldName: "clientType",
      bindedFieldValue: "CorporateClient",
      attrs: {
        type: "text",
        outlined: true,
        label: "Заголовок",
      },
      name: "caption",
      validations: [
        {
          validator: "required",
          errorMessage: "Поле не может быть пустым",
        },
      ],
    },
  ];

  actions: any = {
    getTypes: () => [
      {
        caption: "Физическое лицо",
        className: "PrivateClient",
      },
      {
        caption: "Корпоративный",
        className: "CorporateClient",
      },
    ],
  };

  async addClient(item: any) {
    if (!item?.id) {
      return this.$message("Пожалуйста выберите категорию", "error");
    }

    const model: any = await this.editor.update(this.model);
    if (!model) return;
    model.folderId = item.id;

    switch (model.clientType) {
      case "PrivateClient": {
        delete model.caption;
        break;
      }

      case "CorporateClient": {
        delete model.firstName;
        delete model.lastName;
        delete model.patronymic;
        break;
      }
    }

    this.dataSource.className = model.clientType;

    delete model.clientType;

    await this.dataSource.add(model);
    this.dataSource.className = "PrivateClient";
    await this.dataSource.get();
  }
}
